import request from '../utils/request'
import baseUrl from './baseUrl'

export const prodNoticeList = (data) => request({
  url: baseUrl + '/sendInfo/selectForBack',
  method: 'POST',
  data
})
export const prodNoticeListAdd = (data) => request({
  url: baseUrl + '/sendInfo/sendProdFee',
  method: 'POST',
  data
})
export const activltyNoticeList = (data) => request({
  url: baseUrl + '/sendInfo/selectForBack',
  method: 'POST',
  data
})
export const activltyNoticeListAdd = (data) => request({
  url: baseUrl + '/sendInfo/sendCoupon',
  method: 'POST',
  data
})
export const sendMsg = (data) => request({
  url: baseUrl + '/sendInfo/sendToUser',
  method: 'POST',
  data
})
export const sendMsgTime = (data) => request({
  url: baseUrl + '/baseSet/modifySend',
  method: 'POST',
  data
})
export const sendMsgUserList = (data) => request({
  url: baseUrl + '/sendUser/selectForMem',
  method: 'POST',
  data
})